import React, { lazy, Suspense, useState, useRef, useEffect } from "react"
import { Link } from "gatsby"
import SiteNavigation from "../nav-new/nav"
import Image from "../image-component/image-2023"
import FormSection from "./prediction-engine-form"
import FallbackLoading from "../loader/loader";
import ReactCompareImage from 'react-compare-image';
import Slider from "react-slick"
import "./prediction-engine.scss"
const SliderSection = lazy(() => import('./slider'))

const leftSliderData = [
  {
    controlImage: 'https://res.cloudinary.com/spiralyze/image/upload/spiralyze2022/predictive-engine/patriot-control.webp',
    variantImage: 'https://res.cloudinary.com/spiralyze/image/upload/spiralyze2022/predictive-engine/patriot-variant.webp',
    comparisonCaption: 'Patriot tested this Bulletized Hero layout on their homepage. Our scraper detected it running from 06.19.21 to 10.21.21. The variant won. The scraper captures 12,000 tests like this every month, so you benefit from the global wisdom of all A/B tests.'
  },
  {
    controlImage: 'https://res.cloudinary.com/spiralyze/image/upload/spiralyze2022/predictive-engine/signeasy-control.webp',
    variantImage: 'https://res.cloudinary.com/spiralyze/image/upload/spiralyze2022/predictive-engine/signeasy-variant.webp',
    comparisonCaption: 'SignEasy tested this Personalized Hero and Interface layout on their homepage. Our scraper detected it running from 05/20/22 to 06/04/22. The variant won. The scraper captures 12,000 tests like this every month, so you benefit from the global wisdom of all A/B tests.'
  },
  {
    controlImage: 'https://res.cloudinary.com/spiralyze/image/upload/spiralyze2022/predictive-engine/servicetitan-control.webp',
    variantImage: 'https://res.cloudinary.com/spiralyze/image/upload/spiralyze2022/predictive-engine/servicetitan-variant.webp',
    comparisonCaption: 'Service Titan tested this Personalized Hero Copy layout on their homepage. Our scraper detected it running from 07/05/22 to 11/07/22. The variant won. The scraper captures 12,000 tests like this every month, so you benefit from the global wisdom of all A/B tests.'
  },
  {
    controlImage: 'https://res.cloudinary.com/spiralyze/image/upload/spiralyze2022/predictive-engine/shootproof-control.webp',
    variantImage: 'https://res.cloudinary.com/spiralyze/image/upload/spiralyze2022/predictive-engine/shoot-proof-variant.webp',
    comparisonCaption: 'ShootProof tested this lifestyle image and 3-bullets layout on their homepage. Our scraper detected it running from 10/05/21 to 11/29/21. The variant won. The scraper captures 12,000 tests like this every month, so you benefit from the global wisdom of all A/B tests.'
  }
]
export default function PredictionEngine(props) {
    const [currentSlide, setCurrentSlide] = useState(1)
    const customSlider = useRef();
    const isSSR = typeof window === "undefined"
    const setTopValue = () => {
      if (window.innerWidth < 768) {
        document.querySelector('.slider-controls').style.top = document.querySelector('.slide-item div[data-testid="container"]').offsetHeight + 38 + 'px'
      } else {
        document.querySelector('.slider-controls').style.top = ''
      }
    }
    useEffect(() => {
      window.addEventListener('load', setTopValue, false)
      window.addEventListener('resize', setTopValue, false)
      window.addEventListener('scroll', setTopValue, false)
      return () => {
        window.removeEventListener('load', setTopValue, false)
        window.removeEventListener('resize', setTopValue, false)
        window.removeEventListener('scroll', setTopValue, false)
      }
    }, [])

    return (
      <span className={props.wrapperClass}>
        <section className={"hero-section"}>
          <SiteNavigation />
          <div className="hero-content-wrap">
            <div className="hero-left">
              <div className="hero-superheading">Prediction Engine</div>
              <div className="hero-heading">Get 3X more wins by adapting proven winners</div>
              <div className="hero-subheading">The prediction engine harnesses the collective wisdom of all A/B tests run on the web. It scrapes test results from 34,000 websites. Then, uses Machine Learning to cluster the data and find proven winning patterns for your site.</div>
              <div className="page-anchors"><Link to="/prediction-engine/#scraper">Scraper</Link> · <Link to="/prediction-engine/#clustering">Clustering</Link> · <Link to="/prediction-engine/#predictions">Prediction</Link> · <Link to="/prediction-engine/#results">Results</Link></div>
              <Link className="arrow-button" to="/get-demo/">Get a Demo --></Link>
            </div>
            <div className="hero-right">
              <Image
                sourceFolder={'predictive-engine'}
                lazyLoad={"false"}
                alt={"hero image"}
                fallBackImage={'predictive-hero-image-desktop.webp'}
                imgName={[
                  {
                    imageName: "predictive-hero-image-desktop.webp",
                    minBreakpoint: "992px",
                  },
                  {
                    imageName: "predictive-hero-image-desktop.webp",
                    minBreakpoint: "768px",
                  },
                  {
                    imageName: "predictive-hero-image-mobile.webp",
                    minBreakpoint: "320px",
                  },
                ]}
              />
            </div>
          </div>
        </section>
        <section className="social-proof-section">
          <Image
            sourceFolder={'homepage'}
            lazyLoad={"true"}
            alt={"Social proof"}
            fallBackImage={'2023-social-logos-mobile.webp'}
            imgName={[
              {
                imageName: "2023-social-logos-desktop.webp",
                minBreakpoint: "992px",
              },
              {
                imageName: "2023-social-logos-tablet.webp",
                minBreakpoint: "768px",
              },
              {
                imageName: "2023-social-logos-mobile.webp",
                minBreakpoint: "320px",
              },
            ]}
          />
        </section>
        <section className="scraper-section" id="scraper">
          <div className="scraper-items-wrap">
            <div className="scraper-left">
              <div className="slider-controls">
                <img src="https://res.cloudinary.com/spiralyze/image/upload/spiralyze2022/predictive-engine/slide-left.svg" className="slide-prev" onClick={() => customSlider.current.slickPrev()} />
                <span className="slides-num"><span>{currentSlide} /</span> 4</span>
                <img src="https://res.cloudinary.com/spiralyze/image/upload/spiralyze2022/predictive-engine/slider-right.svg" className="slide-next" onClick={() => customSlider.current.slickNext()} />
              </div>
              <div className="slider-left">
                <Slider
                  draggable={false}
                  swipeToSlide={false}
                  touchMove={false}
                  slidesToShow={1}
                  arrows={true}
                  beforeChange={(currentSlide, nextSlide) => setCurrentSlide(nextSlide + 1)}
                  ref={(slider1) => {
                    customSlider.current = slider1
                  }
                  }
                >
                  {leftSliderData.map((item, index) => {
                    return (
                      <div className="slide-item" key={index}>
                        <ReactCompareImage
                          handle={<img src="https://res.cloudinary.com/spiralyze/image/upload/spiralyze2022/predictive-engine/drag.svg" />}
                          handleSize={56}
                          sliderLineColor={'#2BA3F7'}
                          leftImage={item.controlImage}
                          rightImage={item.variantImage} />
                        <div className="comparison-caption">{item.comparisonCaption}</div>
                      </div>
                    )
                  })}
                </Slider>
              </div>
            </div>
            <div className="scraper-right">
              <div className="slider-right">
                <div className="scraper-heading" ><span>Scraped A/B test data.</span> From across the web.</div>
                <div className="scraper-subheading">The prediction engine scrapes the internet finding A/B tests and tracking the outcomes. It pulls data from the 34,000 websites that actively testing, capturing 12,000 new tests every month</div>
              </div>
            </div>
          </div>
        </section>
        <section className="saas-experts">
          <div className="stats-bg">
            <div className="stats-wrap">
              <div className="stat-item gold">
                <div className="stat-value">130,000</div>
                <div className="stat-name">Websites Scraped</div>
              </div>
              <div className="stat-item blue">
                <div className="stat-value">12,000</div>
                <div className="stat-name">New Tests Per Month</div>
              </div>
              <div className="stat-item red">
                <div className="stat-value">461</div>
                <div className="stat-name">Proven Test Patterns</div>
              </div>
            </div>
          </div>
        </section>
        <section className="clustering-section" id="clustering">
          <div className="clustering-wrap">
            <div className="clustering-left">
              <div className="clustering-heading"><span>Cluster data to find proven.</span> Winning patterns.</div>
            </div>
            <div className="clustering-right">
              <div className="clustering-description">Individual test results are clustered into similar patterns. Multiple winning observations of the same pattern provide greater confidence that the pattern will be performant on your site.</div>
            </div>
          </div>
          <div className="clustering-img"></div>
          <div className="clustering-image-caption">The scraper has detected this Personal Headline 157 times in the last two years.  It won 57 times, nothing-burgered 4 times, and lost 96 times.</div>
        </section>
        <section className="prediction-section" id="predictions">
          <div className="predictions-wrap">
            <div className="predictions-left">
              <div className="predictions-heading"><span>Predictions.</span><br /> For your website.</div>
              <div className="prediction-description">
                <p>Predictions are customized for your website based on your industry, goal-type, and visitor characteristics. The prediction engine overweights data from websites most like yours to generate custom predictions.</p>
                <p>Know how likely a test is to win for you, the average lift when it wins, and how you should prioritize the test.</p>
              </div>
            </div>
            <div className="predictions-right">
              <Image
                sourceFolder={'predictive-engine'}
                lazyLoad={"true"}
                alt={"Prediction engine"}
                fallBackImage={'prediction-image-desktop.webp'}
                imgName={[
                  {
                    imageName: "prediction-image-desktop.webp",
                    minBreakpoint: "992px",
                  },
                  {
                    imageName: "prediction-image-desktop.webp",
                    minBreakpoint: "768px",
                  },
                  {
                    imageName: "prediction-image-mobile.webp",
                    minBreakpoint: "320px",
                  },
                ]}
              />
              <div className="predictive-image-caption">The distribution of results for this test pattern. For a B2B SaaS client, it has a 36% chance of winning, and an average lift of 7%.</div>
            </div>
          </div>
        </section>
        <section className="results-section" id="results">
          <div className="results-wrap">
            <div className="results-left"><span>34% Win Rate.</span><br /> 3X more wins results.</div>
            <div className="results-right">Running proven winning tests using Predictive CRO gets our clients enjoy a 34% win rate, 3X the industry average. Plus they get faster result, with a first win an average of 25 days after kickoff, and a longer runway.</div>
          </div>
          <div className="charts-wrap">
            <div className="chart-item">
              <div className="chart-item-heading">Tests Launched (in first 90 days)</div>
              <div className="chart-item-num">10</div>
              <div className="chart-img">
                <Image sourceFolder={'predictive-engine'} imgName={'num-tests.svg'} />
              </div>
            </div>
            <div className="chart-item">
              <div className="chart-item-heading">Test Win Rate</div>
              <div className="chart-item-num">34%</div>
              <div className="chart-img">
                <Image sourceFolder={'predictive-engine'} imgName={'test-win.svg'} />
              </div>
            </div>
            <div className="chart-item">
              <div className="chart-item-heading">Average Lift</div>
              <div className="chart-item-num">38%</div>
              <div className="chart-img">
                <Image sourceFolder={'predictive-engine'} imgName={'avg-lift.svg'} />
              </div>
            </div>
          </div>
          <div className="charts-caption">Testing using validated winners leads to 3X higher win rates than conventional testing.</div>
        </section>
        <FormSection />
        {
          !isSSR && (
            <Suspense fallback={<FallbackLoading />}>
              <SliderSection />
            </Suspense>
          )
        }
      </span >
    )
  }
