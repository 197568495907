import * as React from "react"
import PredictiveEngine from "../components/prediction-engine/prediction-engine"
import Layout from "../components/layout2023"
import SeoNoIndex from "../components/seo-no-index-ablyft"

const IndexPage = () => (
  <Layout nav={"hidden"}>
    <SeoNoIndex title="Prediction Engine" index={false} follow={false} />
    <PredictiveEngine wrapperClass={"prediction-engine"} />
  </Layout>
)

export default IndexPage
